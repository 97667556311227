<template>
  <div
    v-if="hasData"
    class="import-recap-grid"
  >
    <v-sheet class="overflow-y-auto documents ml-2 mr-0 border-radius">
      <v-expansion-panels
        accordion
        :value="0"
      >
        <v-expansion-panel
          v-for="(entities, dataSourceName) in dataSources"
          :key="dataSourceName"
          class="px-2"
          @click="fileChange(null)"
        >
          <v-expansion-panel-header>
            <v-icon class="mr-4 flex-grow-0">{{$icon('i.DataSource')}}</v-icon>
            <span class="text-no-wrap flex-grow-0 mr-4 text-adapt">{{dataSourceName}}</span>
            <v-spacer />
            <div
              v-if="errorCount(entities)"
              class="d-flex align-center caption flex-grow-0"
            >
              <v-icon
                small
                color="error"
              >{{$icon('i.AlertCircleOutline')}}</v-icon>
              <span class="ml-1 error--text">{{errorCount(entities)}}</span>
            </div>
            <div
              v-if="warningCount(entities)"
              class="d-flex align-center caption flex-grow-0 mx-2"
            >
              <v-icon
                small
                color="warning"
              >{{$icon('i.AlertCircleOutline')}}</v-icon>
              <span class="ml-1 warning--text">{{warningCount(entities)}}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <import-recap-entities
              :entities="entities"
              :displayDatasource="true"
              :entities-summary="dataSourcesSummary[dataSourceName]"
              @file-changed="fileChange"
              :current-file-id="currentFileId"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-sheet>
    <div class="toolbar d-flex align-center ">
      <v-btn-toggle
        v-if="showRows"
        dense
        rounded
        @change="filterChange"
      >
        <v-tooltip
          top
          open-delay="200"
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">
              {{$t("t.Warnings")}}
            </v-btn>
          </template>
          <span>{{$t('t.ShowHideWarnings')}}</span>
        </v-tooltip>
        <v-tooltip
          top
          open-delay="200"
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">
              {{$t("t.Errors")}}
            </v-btn>
          </template>
          <span>{{$t('t.ShowHideErrors')}}</span>
        </v-tooltip>
      </v-btn-toggle>
      <v-menu
        v-if="dstFieldsNotMapped.length > 0"
        offset-y
        nudge-bottom="2"
        left
        transition="scale-transition"
        origin="right top"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            v-on="on"
            color="primary"
            class="ml-4"
          >
            <span>{{dstFieldsNotMapped.length}} {{$t('t.DstFieldsNotMapped')}}</span>
            <v-icon class="ml-1">{{$icon('i.ChevronDown')}}</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <div
              v-for="(f, index) in dstFieldsNotMapped"
              :key="index"
            >
              <v-list-item link>
                <v-list-item-content>
                  <span class="nowrap">{{f.name}}</span>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-card>
      </v-menu>
      <v-menu
        v-if="csvFieldsNotMapped.length > 0"
        offset-y
        nudge-bottom="2"
        left
        transition="scale-transition"
        origin="right top"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            v-on="on"
            color="primary"
            class="ml-4"
          >
            <span>{{csvFieldsNotMapped.length}} {{$t('t.CsvFieldsNotMapped')}}</span>
            <v-icon class="ml-1">{{$icon('i.ChevronDown')}}</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <div
              v-for="(f, index) in csvFieldsNotMapped"
              :key="index"
            >
              <v-list-item link>
                <v-list-item-content>
                  <span class="nowrap">{{f.name}}</span>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-card>
      </v-menu>
    </div>

    <div
      v-if="showRows"
      class="table d-flex flex-column overflow-hidden ml-1"
    >
      <v-card class="overflow-hidden">
        <v-skeleton-loader
          type="table"
          class="custom-fields"
          v-if="loading"
        />
        <v-data-table
          v-else-if="logs.length > 0"
          ref="table"
          class="text-no-wrap"
          dense
          :class="{'no-results':  !logs}"
          :headers="headers"
          :items-per-page="computedPageSize"
          :items="logs"
          fixed-header
          disable-pagination
          hide-default-footer
          hide-default-header
        >
          <template v-slot:header>
            <thead>
              <tr>
                <th
                  v-for="(header, i) in headers"
                  :key="i"
                  class="header"
                  :class="{'sticky-severity-header': !i}"
                >
                  <div
                    v-if="i === 0"
                    class="csv-header d-flex justify-end flex-column"
                  >
                    <div class="d-flex justify-center flex-column">
                      <span>{{$t('t.RowSummary')}}</span>
                    </div>
                  </div>
                  <div
                    v-if="i > 0 && header.dst && header.csv"
                    class="csv-header"
                  >
                    <span class="primary--text">{{header.dst}}</span>
                    <div>{{header.csv.name}}</div>
                  </div>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item: line }">
            <tr>
              <td class="sticky-severity text-center ">
                <div class="d-flex">
                  <v-tooltip
                    open-delay="200"
                    bottom
                    v-if="line.errors.length"
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-icon
                          small
                          v-if="line.severity"
                          :color="line.severity"
                        >{{$icon('i.AlertCircleOutline')}}
                        </v-icon>
                      </div>
                    </template>
                    <span>
                      <div
                        class="d-flex align-center"
                        v-for="({error, text, severity}, i) in line.errors"
                        :key="i"
                      >
                        <v-icon
                          class="mr-2"
                          small
                          :color="severity"
                        >{{$icon('i.AlertCircleOutline')}}</v-icon>
                        <span>{{text}}</span>
                        <v-icon
                          small
                          class="mx-1"
                          style="color: inherit"
                        >{{$icon('i.ChevronRight')}}</v-icon>
                        <span v-html="error" />
                      </div>
                    </span>
                  </v-tooltip>
                  <v-spacer />
                  <div>{{line.line}}</div>
                </div>
              </td>
              <td
                v-for="(header, i) in headers.slice(1)"
                :key="i"
              >
                <template v-if="header.dst && header.csv">
                  <v-tooltip
                    open-delay="200"
                    bottom
                    v-if="fieldHasError(line.errors, header.field)"
                  >
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-badge
                          dot
                          :color="getErrorSeverity(line.errors, header.field)"
                        >
                          <span>{{line.fields[`${header.csv.index}`]}}</span>
                        </v-badge>
                      </div>
                    </template>
                    <span v-html="getError(line.errors, header.field)" />
                  </v-tooltip>
                  <span v-else>{{line.fields[`${header.csv.index}`]}}</span>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
        <template v-else>
          <v-scroll-x-transition hide-on-leave>
            <div
              data-cy="search-no-data"
              class="d-flex flex-column flex-nowrap align-center py-8"
              v-if="isInitialized"
            >
              <div
                class="d-flex flex-shrink-1 my-8"
                style="overflow: hidden; max-height: 50%"
              >
                <v-img
                  contain
                  src="@/assets/no-results.svg"
                />
              </div>
              <div class="text-h3 py-4">
                {{$t("t.NoResult")}}
              </div>
            </div>
          </v-scroll-x-transition>
        </template>
      </v-card>
      <v-pagination
        v-model="computedPage"
        v-if="pageCount > 1"
        :length="pageCount"
        :total-visible="7"
      >
      </v-pagination>
    </div>
    <div
      v-else
      class="d-flex justify-center table"
    >
      <span class="text-h4">{{$t('t.SelectEntityFile')}}</span>
    </div>
  </div>
  <div v-else>
    <span class="mx-2 error--text">
      {{$t('t.NoFileImported')}}
    </span>
  </div>
</template>

<script>
import GlobalSearch from '@/pages/search/controllers/global-search'

export default {
  mounted () {
    GlobalSearch.registerInterceptor(this.searchInterceptor)
    GlobalSearch.onSendSearchText(this.getFileRecap)
  },
  activated () {
    GlobalSearch.registerInterceptor(this.searchInterceptor)
    GlobalSearch.onSendSearchText(this.getFileRecap)
    if (!this.hasData) {
      this.refreshDocument()
    }
  },
  deactivated () {
    GlobalSearch.offSendSearchText(this.getFileRecap)
    GlobalSearch.removeInterceptor()
  },
  beforeDestroy () {
    GlobalSearch.offSendSearchText(this.getFileRecap)
    GlobalSearch.removeInterceptor()
  },
  components: {
    ImportRecapEntities: () => import('./import-recap-entities')
  },
  computed: {
    showRows () {
      return this.currentFileId
    },
    computedPageSize () {
      return 20
    },
    displayPagination () {
      return this.count > this.computedPageSize
    },
    computedPage: {
      get () {
        return this.page || 1
      },
      set (v) {
        if (this.page !== v) {
          this.page = v
          this.resetScroll()
          this.getFileRecap()
        }
      }
    },
    hasData () {
      return !this.lodash.isEmpty(this.dataSources)
    },
    singleDataSource () {
      return Object.keys(this.dataSources).length === 1
    }
  },
  data () {
    return {
      isInitialized: false,
      count: 0,
      csvFieldsNotMapped: [],
      currentFileId: null,
      dataSources: {},
      dstFieldsNotMapped: [],
      filterLevel: null,
      headers: [],
      http: this.$http().debounced(),
      loading: false,
      logs: [],
      page: 1,
      pageCount: 0,
      searchInterceptor: { searchText: '' }
    }
  },
  methods: {
    filterChange (v) {
      v = v + 1
      this.page = 1
      this.filterLevel = v
      this.getFileRecap()
    },
    fileChange (file) {
      this.filterLevel = null
      this.page = 1
      this.resetScroll()
      this.currentFileId = file?.fileId

      this.getFileRecap()
    },
    isSelected (id) {
      return id === this.dataDocumentType
    },
    buildHeader (headers) {
      this.headers = [
        {
          text: this.$t('t.Severity'),
          value: 'severity',
          field: { Severity: 'Severity' },
          filterable: false,
          sortable: false,
          hint: ''
        },
        ...headers.map((col, index) => ({
          csv: col.csv,
          dst: col.dst?.name,
          value: index,
          field: col.dst?.field,
          hint: col['file-header'],
          sortable: false
        }))
      ]

      this.dstFieldsNotMapped = headers.filter(_ => !_.csv).map(f => f.dst)
      this.csvFieldsNotMapped = headers.filter(_ => !_.dst).map(f => f.csv)
    },
    async getFileRecap () {
      this.isInitialized = false
      this.logs = []
      this.csvFieldsNotMapped = []
      this.dstFieldsNotMapped = []
      if (!this.currentFileId) {
        return
      }

      const { data } = await this.http.get(
        `/core/import/${this.id}/${this.currentFileId}`,
        {
          params: {
            page: this.page - 1,
            pageSize: this.computedPageSize,
            filterLevel: this.filterLevel || 0,
            search: this.searchInterceptor.searchText
          }
        }
      )

      this.buildHeader(data.headers)
      this.logs = data.logs

      if (this.page === 1) {
        this.count = data.count
        this.pageCount = Math.ceil(data.count / this.computedPageSize)
      }

      this.isInitialized = true
    },
    async refreshDocument () {
      this.loading = true
      try {
        const { data } = await this.http.get(`/core/import/${this.id}`)
        this.dataSources = data.dataSources
        if (!this.lodash.isEmpty(this.dataSources)) {
          const firstDataSource = Object.values(this.dataSources)[0]
          const firstFileOfFirstEntity = Object.values(firstDataSource)[0][0]
          this.fileChange(firstFileOfFirstEntity)
        }
      } finally {
        this.loading = false
      }
    },
    warningCount (entities) {
      return Object.values(entities).reduce((acc, files) => acc + files.reduce((acc, file) => acc + file.warningCount, 0), 0)
    },
    errorCount (entities) {
      return Object.values(entities).reduce((acc, files) => acc + files.reduce((acc, file) => acc + file.errorCount, 0), 0)
    },
    fieldHasError (errors, field) {
      return errors.filter(e => this.lodash.isEqual(e.field, field)).length > 0
    },
    getErrorSeverity (errors, field) {
      return errors.find(e => this.lodash.isEqual(e.field, field))?.severity
    },
    getError (errors, field) {
      return errors.find(e => this.lodash.isEqual(e.field, field))?.error
    },
    resetScroll () {
      this.$refs.table?.$el.querySelector('.v-data-table__wrapper').scroll(0, 0)
    }
  },
  watch: {
    importId: {
      immediate: true,
      handler (id) {
        this.id = id
        if (this.id) {
          this.refreshDocument()
        }
      }
    }
  },
  props: {
    dataSourcesSummary: Object,
    importId: String
  }
}
</script>

<style lang="stylus" scoped>
.no-results >>> &>*
  overflow hidden

.import-recap
  width 100%

.import-recap-grid
  display grid
  flex-grow 1
  grid-template 'documents toolbar' 'documents table' '. table' 1fr / min-content 1fr

.toolbar
  grid-area toolbar
  padding 10px
  padding-top 0px

.documents
  grid-area documents

.table
  grid-area table

.sticky-severity
  position sticky !important
  left 0
  z-index 1
  background-color var(--bg-transparency) !important
  border-right 1px solid rgb(128, 128, 128)

.header
  text-align left

.sticky-severity-header
  left 0
  text-align center !important
  z-index 3 !important
  border-right 1px solid rgb(128, 128, 128)

.dst-field
  font-weight bold

>>>
  .v-data-table, .v-data-table__wrapper
    height 100%

.v-data-table > .v-data-table__wrapper > table > thead > tr > th
  font-size 14px
  height 48px

.csv-header
  margin 0.5em 0

@css {
  .text-adapt, >>> .text-adapt {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: max(200px, 20vw);
  }
}
</style>
