var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasData)?_c('div',{staticClass:"import-recap-grid"},[_c('v-sheet',{staticClass:"overflow-y-auto documents ml-2 mr-0 border-radius"},[_c('v-expansion-panels',{attrs:{"accordion":"","value":0}},_vm._l((_vm.dataSources),function(entities,dataSourceName){return _c('v-expansion-panel',{key:dataSourceName,staticClass:"px-2",on:{"click":function($event){return _vm.fileChange(null)}}},[_c('v-expansion-panel-header',[_c('v-icon',{staticClass:"mr-4 flex-grow-0"},[_vm._v(_vm._s(_vm.$icon('i.DataSource')))]),_c('span',{staticClass:"text-no-wrap flex-grow-0 mr-4 text-adapt"},[_vm._v(_vm._s(dataSourceName))]),_c('v-spacer'),(_vm.errorCount(entities))?_c('div',{staticClass:"d-flex align-center caption flex-grow-0"},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(_vm._s(_vm.$icon('i.AlertCircleOutline')))]),_c('span',{staticClass:"ml-1 error--text"},[_vm._v(_vm._s(_vm.errorCount(entities)))])],1):_vm._e(),(_vm.warningCount(entities))?_c('div',{staticClass:"d-flex align-center caption flex-grow-0 mx-2"},[_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v(_vm._s(_vm.$icon('i.AlertCircleOutline')))]),_c('span',{staticClass:"ml-1 warning--text"},[_vm._v(_vm._s(_vm.warningCount(entities)))])],1):_vm._e()],1),_c('v-expansion-panel-content',[_c('import-recap-entities',{attrs:{"entities":entities,"displayDatasource":true,"entities-summary":_vm.dataSourcesSummary[dataSourceName],"current-file-id":_vm.currentFileId},on:{"file-changed":_vm.fileChange}})],1)],1)}),1)],1),_c('div',{staticClass:"toolbar d-flex align-center "},[(_vm.showRows)?_c('v-btn-toggle',{attrs:{"dense":"","rounded":""},on:{"change":_vm.filterChange}},[_c('v-tooltip',{attrs:{"top":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$t("t.Warnings"))+" ")])]}}],null,false,258983554)},[_c('span',[_vm._v(_vm._s(_vm.$t('t.ShowHideWarnings')))])]),_c('v-tooltip',{attrs:{"top":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$t("t.Errors"))+" ")])]}}],null,false,1842275536)},[_c('span',[_vm._v(_vm._s(_vm.$t('t.ShowHideErrors')))])])],1):_vm._e(),(_vm.dstFieldsNotMapped.length > 0)?_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"2","left":"","transition":"scale-transition","origin":"right top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"small":"","color":"primary"}},on),[_c('span',[_vm._v(_vm._s(_vm.dstFieldsNotMapped.length)+" "+_vm._s(_vm.$t('t.DstFieldsNotMapped')))]),_c('v-icon',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$icon('i.ChevronDown')))])],1)]}}],null,false,683390913)},[_c('v-card',[_c('v-list',_vm._l((_vm.dstFieldsNotMapped),function(f,index){return _c('div',{key:index},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-content',[_c('span',{staticClass:"nowrap"},[_vm._v(_vm._s(f.name))])])],1)],1)}),0)],1)],1):_vm._e(),(_vm.csvFieldsNotMapped.length > 0)?_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"2","left":"","transition":"scale-transition","origin":"right top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"small":"","color":"primary"}},on),[_c('span',[_vm._v(_vm._s(_vm.csvFieldsNotMapped.length)+" "+_vm._s(_vm.$t('t.CsvFieldsNotMapped')))]),_c('v-icon',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$icon('i.ChevronDown')))])],1)]}}],null,false,2946907009)},[_c('v-card',[_c('v-list',_vm._l((_vm.csvFieldsNotMapped),function(f,index){return _c('div',{key:index},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-content',[_c('span',{staticClass:"nowrap"},[_vm._v(_vm._s(f.name))])])],1)],1)}),0)],1)],1):_vm._e()],1),(_vm.showRows)?_c('div',{staticClass:"table d-flex flex-column overflow-hidden ml-1"},[_c('v-card',{staticClass:"overflow-hidden"},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"custom-fields",attrs:{"type":"table"}}):(_vm.logs.length > 0)?_c('v-data-table',{ref:"table",staticClass:"text-no-wrap",class:{'no-results':  !_vm.logs},attrs:{"dense":"","headers":_vm.headers,"items-per-page":_vm.computedPageSize,"items":_vm.logs,"fixed-header":"","disable-pagination":"","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.headers),function(header,i){return _c('th',{key:i,staticClass:"header",class:{'sticky-severity-header': !i}},[(i === 0)?_c('div',{staticClass:"csv-header d-flex justify-end flex-column"},[_c('div',{staticClass:"d-flex justify-center flex-column"},[_c('span',[_vm._v(_vm._s(_vm.$t('t.RowSummary')))])])]):_vm._e(),(i > 0 && header.dst && header.csv)?_c('div',{staticClass:"csv-header"},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(header.dst))]),_c('div',[_vm._v(_vm._s(header.csv.name))])]):_vm._e()])}),0)])]},proxy:true},{key:"item",fn:function(ref){
var line = ref.item;
return [_c('tr',[_c('td',{staticClass:"sticky-severity text-center "},[_c('div',{staticClass:"d-flex"},[(line.errors.length)?_c('v-tooltip',{attrs:{"open-delay":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(line.severity)?_c('v-icon',{attrs:{"small":"","color":line.severity}},[_vm._v(_vm._s(_vm.$icon('i.AlertCircleOutline'))+" ")]):_vm._e()],1)]}}],null,true)},[_c('span',_vm._l((line.errors),function(ref,i){
var error = ref.error;
var text = ref.text;
var severity = ref.severity;
return _c('div',{key:i,staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":severity}},[_vm._v(_vm._s(_vm.$icon('i.AlertCircleOutline')))]),_c('span',[_vm._v(_vm._s(text))]),_c('v-icon',{staticClass:"mx-1",staticStyle:{"color":"inherit"},attrs:{"small":""}},[_vm._v(_vm._s(_vm.$icon('i.ChevronRight')))]),_c('span',{domProps:{"innerHTML":_vm._s(error)}})],1)}),0)]):_vm._e(),_c('v-spacer'),_c('div',[_vm._v(_vm._s(line.line))])],1)]),_vm._l((_vm.headers.slice(1)),function(header,i){return _c('td',{key:i},[(header.dst && header.csv)?[(_vm.fieldHasError(line.errors, header.field))?_c('v-tooltip',{attrs:{"open-delay":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-badge',{attrs:{"dot":"","color":_vm.getErrorSeverity(line.errors, header.field)}},[_c('span',[_vm._v(_vm._s(line.fields[("" + (header.csv.index))]))])])],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getError(line.errors, header.field))}})]):_c('span',[_vm._v(_vm._s(line.fields[("" + (header.csv.index))]))])]:_vm._e()],2)})],2)]}}],null,false,887451870)}):[_c('v-scroll-x-transition',{attrs:{"hide-on-leave":""}},[(_vm.isInitialized)?_c('div',{staticClass:"d-flex flex-column flex-nowrap align-center py-8",attrs:{"data-cy":"search-no-data"}},[_c('div',{staticClass:"d-flex flex-shrink-1 my-8",staticStyle:{"overflow":"hidden","max-height":"50%"}},[_c('v-img',{attrs:{"contain":"","src":require("@/assets/no-results.svg")}})],1),_c('div',{staticClass:"text-h3 py-4"},[_vm._v(" "+_vm._s(_vm.$t("t.NoResult"))+" ")])]):_vm._e()])]],2),(_vm.pageCount > 1)?_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.computedPage),callback:function ($$v) {_vm.computedPage=$$v},expression:"computedPage"}}):_vm._e()],1):_c('div',{staticClass:"d-flex justify-center table"},[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.$t('t.SelectEntityFile')))])])],1):_c('div',[_c('span',{staticClass:"mx-2 error--text"},[_vm._v(" "+_vm._s(_vm.$t('t.NoFileImported'))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }